:root {
	--list-postfix: ",";
	--list-prefix: "-";
	--list-unstyled: {
		margin: 0;
		padding: 0;
		list-style: none;
	}
}
/* initial list styles */
dl {
	margin-bottom: 1.5rem;
}

dt {
	font-weight: var(--font-bold);
}

dd {
	margin-left: 0;
	margin-bottom: 1.5rem;
}

ol,
ul {
	line-height: var(--line-height);
	margin-top: 0;
	margin-bottom: 1.5rem;

	& ol,
	& ul {
		margin-bottom: 0;
	}
}

.list {
	@apply --list-unstyled;
}
.list-prefix, .list-postfix {
	padding-left: 1rem;
}
.list-prefix {
	list-style: none;
	& > li::before {
		content: var(--list-prefix);
		margin-right: 5px;
		color: var(--color-text);
	}
}

.list-postfix {
	list-style: none;
	& > li {
		&::after {
			content: var(--list-postfix);
		}
	}
}
.list_icon {
	.list__item {
		display: inline-block;
		margin: 0 0.5rem 1.5rem 0;
		.icon {
			display: inline-block;
			vertical-align: middle;
			padding: 0 .2rem;
			font-size: 1.8rem;
		}
		.text {
			display: inline-block;
			text-align: left;
			line-height: 1;
		}
	}
}
.list_value, .list_total {
	li {
		display: block;
		margin-bottom: 5px;
	}
}
.list_total {
	li {
		display: flex;
		justify-content: space-between;
	}
}