.slider {
    user-select: none;
    &_main {
        min-height: 130px;
        .swiper-slide {
            text-align: center;
        }
    }
}
/* carousel */
.carousel.is-loading .carousel__item {
    opacity: 0;
}
.carousel_product {
    margin-bottom: 2rem;
}
.carousel .carousel__item {
    width: 100%;
    display: block;
}
.carousel .carousel__item img {
    width: 100%;
    height: auto;
    vertical-align: top;
}
.carousel_product-nav .carousel__item {
    height: 90px;
    width: 90px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 10px;
    -webkit-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
.carousel_product-nav .carousel__item.is-nav-selected img {
    opacity: .8;
}