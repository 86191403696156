.header {
  position: relative;
  padding: 20px 15px;
  &__logo {
    display: block;
    position: relative;
    z-index: 1;
    img {
      display: inline-block;
    }
  }
  &_main {
    padding-left: 37%;
    @media (--breakpoint-sm-down) {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  &_bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255,255,255, .8);
    }
  }
}