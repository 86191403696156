:root {
	--input-font-size: 1rem;
	--input-font-family: var(--font-family);
	--input-font-weight: var(--font-normal);
	--input-color: var(--color-text);
	--input-border-color: var(--component-border-color);
	--input-border-width: 1px;
	--input-bg-color: var(--color-white);


	--input-invalid-color: var(--state-danger);
	--input-invalid-border-color: var(--state-danger);
	--input-valid-color: var(--state-success);
	--input-valid-border-color: var(--state-success);


	--placeholder-color: var(--color-muted);
}

/*  Forms initial styles */
button,
input,
optgroup,
select,
textarea {
	font-family: sans-serif;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
}
button,
input {
	overflow: visible;
}
button,
select {
	text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	border-style: none;
	padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
	outline: 1px dotted ButtonText;
}
button, input[type="submit"] {
	cursor: pointer;
}
legend {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: inherit;
	display: table;
	max-width: 100%;
	padding: 0;
	white-space: normal;
}
fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}
fieldset legend {
	font-weight: 500;
	padding: 0 10px;
}
progress {
	display: inline-block;
	vertical-align: baseline;
}
textarea {
	overflow: auto;
}
[type="checkbox"],
[type="radio"] {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}
[type="search"] {
	-webkit-appearance: textfield;
	outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}
::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit;
}

/*  Forms base styles */

input,
select,
textarea {
	display: inline-block;
	margin: 0;
	font-family: var(--input-font-family);
	font-size: var(--input-font-size);
	font-weight: var(--input-font-weight);
	color: var(--input-color);
	vertical-align: middle;
	box-shadow:	none;
	background-color: var(--input-bg-color);
	white-space: nowrap;
	&, &:focus {
		outline: none;
	}

	&[disabled] {
		opacity: .5;
		cursor: not-allowed;
	}

	&[readonly] {
		background-color: var(--color-lightgrey);
	}

	&[type="color"] {
		padding: .5rem;

		&::-webkit-color-swatch-wrapper {
			padding: 0;
		}

		&::-webkit-color-swatch {
			border: none;
		}
	}
}
input, textarea {
	&:focus {
		&::placeholder {
			opacity: 0;
		}
	}
}
select {
	position: relative;
	background-image: url(../images/arrow-select.svg);
	background-position: right .4rem center;
	background-repeat: no-repeat;
	background-size: .75rem;
	border-color: var(--color-black);
	padding: 0.625rem 1.5rem 0.625rem 1.25rem;
	min-height: 44px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	&[multiple] {
		height: auto;
		padding-right: 0;
	}
}
select:required:invalid {
	color: var(--placeholder-color);
}
option[value=""][disabled] {
	display: none;
}
option {
	color: var(--color-text);
}
textarea {
	height: auto;
	vertical-align: top;
	resize: vertical;
	line-height: var(--line-height);
	white-space: normal;
}

input[type="checkbox"],
input[type="radio"] {
	vertical-align: 1px;
}

/* delete arrow input type number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield;
	font-weight: normal;
}
/* hide cross in search input ie */
input[type=search]::-ms-clear {
	size: 0;
}

.fieldset {
	margin-bottom: 1.5rem;

	& legend {
		font-weight: var(--font-bold);
		padding: 0 .25rem;
	}

	& > :last-child {
		margin-bottom: 0;
	}
}


.label {
	display: inline-block;
	margin-bottom: .25rem;

	& + label {
		margin-left: 1rem;
	}
}

.form {
	&__row {
		position: relative;
		margin-bottom: 1.5rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&_horizontal {
		input,
		select,
		textarea {
			display: block;
			width: 100%;
		}
	}
}



/* Validation */
.is-error {
	border-color: var(--input-invalid-border-color) !important;
}
.is-success {
	border-color: var(--input-valid-border-color) !important;
}
.error {
	color: var(--input-invalid-color);
}
