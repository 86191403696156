/* Remodal - v1.0.6  */


/* Hide scroll bar */

html.remodal-is-locked {
	overflow: hidden;
	touch-action: none;
}


/* Anti FOUC */

.remodal,
[data-remodal-id] {
	display: none;
}


/* Necessary styles of the overlay */

.remodal-overlay {
	position: fixed;
	z-index: 9999;
	top: -5000px;
	right: -5000px;
	bottom: -5000px;
	left: -5000px;
	display: none;
}


/* Necessary styles of the wrapper */

.remodal-wrapper {
	position: fixed;
	z-index: 10000;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: none;
	overflow: auto;
	text-align: center;
	-webkit-overflow-scrolling: touch;
	&::after {
		display: inline-block;
		height: 100%;
		margin-left: -0.05em;
		content: '';
	}
}
/* Fix iPad, iPhone glitches */

.remodal-overlay,
.remodal-wrapper {
	backface-visibility: hidden;
}


/* Necessary styles of the modal dialog */

.remodal {
	position: relative;
	outline: none;
	text-size-adjust: 100%;
	text-align: left;
}


.remodal-is-initialized {
	/* Disable Anti-FOUC */
	display: inline-block;
}


.remodal-bg.remodal-is-opening,
.remodal-bg.remodal-is-opened {
	filter: blur(3px);
}


.remodal-overlay {
	background: rgba(43, 46, 56, 0.45);
}


.remodal-overlay.remodal-is-opening,
.remodal-overlay.remodal-is-closing {
	animation-duration: 0.3s;
	animation-fill-mode: forwards;
}


.remodal-overlay.remodal-is-opening {
	animation-name: remodal-overlay-opening-keyframes;
}


.remodal-overlay.remodal-is-closing {
	animation-name: remodal-overlay-closing-keyframes;
}


.remodal-wrapper {
	padding: 10px 10px 0;
	@media (max-width: 469px) {
		padding: 0;
	}
}


.remodal {
	box-sizing: border-box;
	transform: translate3d(0, 0, 0);
	transform-origin: 50% 50%;
	margin-bottom: 15px;
}

.remodal.remodal-is-opening,
.remodal.remodal-is-closing {
	animation-duration: 0.3s;
	animation-fill-mode: forwards;
}

.remodal.remodal-is-opening {
	animation-name: remodal-opening-keyframes;

}

.remodal.remodal-is-closing {
	animation-name: remodal-closing-keyframes;
}


.remodal,
.remodal-wrapper::after {
	vertical-align: middle;
}

.modal {
	width: 85%;
	&__close {
		position: absolute;
		top: 10px;
		right: 15px;
		width: 28px;
		height: 28px;
		padding: 0;
		text-decoration: none;
		border-radius: 50%;
		line-height: 26px;
		border: 1px solid var(--color-text);
		color: var(--color-text);
		transition: all 0.2s linear;
		text-align: center;
		cursor: pointer;
		&:hover {
			opacity: .65;
		}
	}
	&__content {
		padding: 40px 30px 20px;
		color: var(--color-text);
		background: #fff;
	}
	&__header {
		text-align: center;
		.title {
			margin: 0 0 20px;
		}
	}
}

@keyframes remodal-opening-keyframes {
	from {
		margin-bottom: 10%;
		opacity: 0;
	}
	to {
		margin-bottom: 0;
		opacity: 1;
	}
}


@keyframes remodal-closing-keyframes {
	from {
		margin-bottom: 0;
		opacity: 1;
	}
	to {
		margin-bottom: 10%;
		opacity: 0;
	}
}

@keyframes remodal-overlay-opening-keyframes {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes remodal-overlay-closing-keyframes {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}



@media (min-width: 992px) {
	.modal_sm {
		max-width: 840px;
	}
}
@media (min-width: 768px) {
	.modal_md {
		max-width: 640px;
	}
}
@media only screen and (min-width: 480px){
	.modal_xs {
		width: 370px;
	}
}
