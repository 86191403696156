.gallery {
	padding: 0;
	margin-bottom: 0;
	list-style: none;
	&__link {
		display: block;
		position: relative;
		overflow: hidden;
		text-decoration: none;
	}
	&__image {
		display: block;
		object-fit: cover;
	}
}
.gallery_caption {
	@extend .row;
	.gallery__item {
		@extend .col-12;
		@extend .col-md-6;
		@extend .col-lg-4;
		margin-bottom: 40px;
	}
	.gallery__link {
		height: 100%;
		min-height: 220px;
		&:hover {
			.gallery__caption {
				height: 100%;
				padding-top: 30%;
				background-color: rgba(0,0,0,0.5);
			}
		}
	}
	.gallery__image {
		size: 100%;
		max-width: none;
	}
	.gallery__caption {
		position: absolute;
		width: 100%;
		bottom: 0;
		transition: 0.4s all;
		height: 50px;
		padding-top: 10px;
		background-color: rgba(0,0,0,0.8);
		text-align: center;
		.gallery__title {
			margin: 0;
			text-transform: uppercase;
			color: #fff;
		}
	}
}
.gallery_photo {
	display: flex;
	overflow: hidden;
	flex-wrap: wrap;
	padding: 0 15px;
	@media (--breakpoint-lg-up) {
		padding: 0 60px;
	}
	&::after {
		content: '';
		flex-grow: 999999999;
		min-width: 250px;
		height: 0;
	}
	.gallery__item {
		position: relative;
		display: block;
		height: 250px;
		margin: 2px;
		flex-grow: 1;
		transition: opacity 300ms;
		background: #f7f7f7 url(../images/loading.gif) no-repeat center;
	}
	.gallery__image {
		height: 250px;
		max-width: 100%;
		min-width: 100%;
		vertical-align: bottom;
	}
}
.gallery_single {
	padding: 0 15px;
	@media (--breakpoint-lg-up) {
		padding: 0 60px;
	}
}
.gallery_shop {
	@extend .row;
	.gallery__item {
		@extend .col-12;
		@extend .col-sm-6;
		@extend .col-md-4;
		@extend .col-lg-3;
		display: flex;
		margin-bottom: 30px;
		> * {
			width: 100%;
			height:	100%;
		}
	}
}

.lazyload,
.lazyloading {
	opacity: 0;
}
.lazyloaded {
	opacity: 1;
	transition: opacity 300ms;
}