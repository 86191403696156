.navbar {
  .container {
    position: relative;
    width: 100%;
    max-width: none;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  &_main {
    position: absolute;
    top: 1px;
    left: 10%;
    width: 23%;
    height: auto;
    text-align: center;
    background-image: url(../images/ribbon.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    z-index: 100;
    .container {
      padding: 10px 15px 65%;
      background-color: #fff;
      background-clip: content-box;
      @media only screen and (min-width: 2000px) {
        padding: 10px 10px 50%;
      }
    }
    .navbar__logo {
      display: inline-block;
      max-width: 90%;
      padding: 10px 5% 0;
    }
    .menu_nav {
      padding-top: 10%;
      .menu__item {
        display: block;
        padding: .5vw 0;
        &:last-child {
          .menu__link {
            &::after {
              content: none
            }
          }
        }
      }
      .menu__link {
        display: block;
        &::after {
          bottom: -0.5vw;
          width: 108px;
          max-width: 50%;
        }
      }
    }
  }
  @media (--breakpoint-sm-down) {
    height: 45px;
    .menu_nav {
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    }
    .container {
      padding: 0;
    }
    &_main {
      position: static;
      padding: 0;
      width: auto;
      height: 0;
      background: none;
    }
    .navbar__logo {
      display: none;
    }
  }
}
.navbar__toggle {
  position: absolute;
  top: 10px;
  left: 15px;
  size: 30px 25px;
  border: 0;
  padding: 0;
  z-index: calc(var(--z-index-nav) + 1);
  span {
    background-color: #000;
    display: block;
    width: 30px;
    height: 2px;
    left: 0;
    position: absolute;
    transform-origin: center;
    transition-duration: 86ms;
    transition-property: background-color, opacity, transform;
    transition-timing-function: ease-out;
    &:first-child {
      top: calc(50% - 10px);
    }
    &:nth-child(2) {
      top: calc(50% - 1px);
    }
    &:nth-child(3) {
      top: calc(50% + 9px);
    }
  }
  @media (--breakpoint-md-up) {
    display: none
  }
  &.is-active {
    position: fixed;
    span {
      &:first-child {
        transform: translateY(10px) rotate(45deg);
      }
      &:nth-child(3) {
        transform: translateY(-9px) rotate(-45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
    }
  }
}
body {
  &.is-navbar-open {
    overflow: hidden;
    .menu_nav {
      @extend .ui-scroll;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(255, 255, 255, .95);
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      z-index: var(--z-index-nav);
      .menu__item {
        animation-name: navItemFadeIn;
        animation-duration: .5s;
        animation-fill-mode: both;
        animation-direction: normal;
        &:nth-child(2) {
          animation-delay: .2s
        }
        &:nth-child(3) {
          animation-delay: .4s
        }
        &:nth-child(4) {
          animation-delay: .6s
        }
        &:nth-child(5) {
          animation-delay: .8s
        }
        &:nth-child(6) {
          animation-delay: 1s
        }
        &:nth-child(7) {
          animation-delay: 1.2s
        }
      }
      .menu__link {
        font-size: 5vw;
        padding: 10px 15px;
      }
    }
  }
}
@keyframes navItemFadeIn {
  from {
    transform: translateY(0);
    opacity: 0
  }

  to {
    transform: translateY(-10px);
    opacity: 1
  }
}
