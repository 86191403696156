.banner {
  position: relative;
  padding: 20px 0;
  margin: 0 auto 30px;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0, .4);
  }
  &__caption {
    position: relative;
    z-index: 10;
    text-align: center;
    &, .title {
      color: #fff;
    }
    .title {
      margin-top: 0;
    }
    .button {
      min-width: 200px;
    }
  }
}