:root {
	--color-white: white;
	--color-blue: #428bca;
	--color-teal: #39cccc;
	--color-green: #2ecc40;
	--color-greenwave: #0c606c;
	--color-yellow: #ffdc00;
	--color-orange: #ff851b;
	--color-red: #ff4136;
	--color-black: #000;
	--color-darkgrey: #666;
	--color-grey: #999;
	--color-lightgrey: #ddd;

	--state-primary: var(--color-black);
	--state-secondary: var(--color-white);
	--state-success: var(--color-green);
	--state-info: var(--color-blue);
	--state-warning: var(--color-orange);
	--state-danger: var(--color-red);

	--body-bg-color: var(--color-white);
	--color-text: #333;
	--color-muted: var(--color-grey);
	--border-radius: 0;
	--base-unit: 1.5rem;

	--component-bg-color: #fff;
	--component-border-color: #000;
	--component-border-radius: var(--border-radius);
	--component-border-width: 1px;
	--component-focus-width: 1px;
	--component-padding-x: var(--base-unit);
	--component-padding-y: var(--base-unit);
	--component-speed: .25s;
	--component-box-shadow-inner: inset 0 1px 1px rgba(0, 0, 0, .05);
	--component-box-shadow-outer: 0 1px 0 rgba(0, 0, 0, .05);

	--spacing-xs: .5rem;
	--spacing-sm: 1rem;
	--spacing-md: 1.5rem;
	--spacing-lg: 2rem;
	--spacing-xl: 2.5rem;

	--z-index-nav: 1000;
	--z-index-popover: 999;
	--centered: {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
	};
	--clearfix: {
		&:before,
		&:after {
			content: '';
			display: table;
		}
		&:after {
			clear: both;
		}
	};
}
