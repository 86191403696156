.breadcrumbs {
  margin-top: 15px;
  margin-bottom: 15px;
  list-style: none;
  &__item {
    display: inline-block;
    padding: 0 3px 0 0;
    &::before {
      content: "/";
      color: var(--color-darkgrey);
    }
    &:first-child .breadcrumbs__link, &:first-child span {
      padding-left: 0;
    }
    &:first-child {
      &::before {
        content: none
      }
    }
  }
  &__link, span {
    padding-left: 3px;
    font-size: var(--font-size-small);
  }
  &__link {

  }
  span {
    color: var(--color-darkgrey);
  }

}