.section {
  padding-top: 100px;
  padding-bottom: 60px;
  @media (--breakpoint-sm-down) {
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .section__title {
    margin: 0 0 1rem;
  }
  &_subscription {
    padding: 50px 0;
    background-color: #000;
    text-align: center;
    &, .title, .subtitle {
      color: #fff;
    }
    .title {
      margin: 0 0 8px;
      text-transform: uppercase;
    }
    .subtitle {
      margin-bottom: 20px;
    }
    textarea, input {
      font-size: 1.5rem;
      padding: 8px 20px;
    }
    .button_secondary, textarea, input {
      width: 100%;
      height: 52px;
    }
    @media(--breakpoint-sm-down) {
      .button_secondary, textarea, input {
        margin-bottom: 10px;
      }
    }
  }
  &_contact {
    .title {
      margin-top: 0;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: var(--font-light);
    }
    .address-new {
      margin-top: 30px;
      font-weight: var(--font-bold);
      color: #b90000;
    }
  }
  &_first {
    padding-top: 0 !important;
  }
  &_single {
    padding: 0;
  }
  &_map {
    padding: 0;
    .map {
      height: 400px;
      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }
  &_photo {
    padding-top: 0;
    @media(--breakpoint-sm-down) {
      padding-top: 0;
    }
    .gallery_photo {
      margin-top: 40px;
      @media(--breakpoint-sm-down) {
        margin-top: 20px;
      }
    }

  }
}
