/*  Print */

@media print {
	*,
	*::before,
	*::after,
	*::first-letter,
	*::first-line {
		background: transparent !important;
		color: #000 !important;
		box-shadow: none !important;
		text-shadow: none !important;
	}
	a,
	a:visited {
		text-decoration: underline;
	}
	a[href]::after {
		content: " (" attr(href) ")";
	}
	abbr[title]::after {
		content: " (" attr(title) ")";
	}

	/*
    Don't show links that are fragment identifiers,
    or use the `javascript:` pseudo protocol
    */
	a[href^="#"]::after,
	a[href^="javascript:"]::after {
		content: "";
	}
	pre {
		white-space: pre-wrap !important;
	}


	pre, blockquote {
		border: 1px solid #999;
		page-break-inside: avoid
	}
	/*
	* Printing Tables:
	* http://css-discuss.incutio.com/wiki/Printing_Tables
	*/
	thead {
		display: table-header-group
	}


	tr,	img {
		page-break-inside: avoid
	}


	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3
	}


	h2,
	h3 {
		page-break-after: avoid
	}

}
