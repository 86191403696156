.order {
  position: relative;
  counter-reset: checkoutSection;
  &__value {
    display: none;
  }
  &__row {
    @media (--breakpoint-lg-up) {
      display: flex;
      margin-right: -10px;
      margin-left: -10px;
    }
  }
  &__column {
    @media (--breakpoint-lg-up) {
      width: calc(50% - 16.5px);
      padding: 0 10px;
    }
  }
  &__section {
    box-sizing: border-box;
    padding: 22px;
    background-color: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    &.is-close {
      opacity: .6;
      padding-bottom: 0;
    }
  }
  &__section {
    margin-bottom: 22px;
    position: relative;
    font-size: 11px;
    .ui-input, select, .button {
      width: 100%;
    }
    &.is-completed, &.is-close {
      .order__form {
        display: none;
      }
    }
    &.is-completed {
      .order__header {
        .title {
          &::before {
            border-color: #9F9F9F;
            color: #fff;
            background-color: #9F9F9F;
          }
        }
        .edit-link {
          display: block;
        }
      }
      .order__value {
        display: block;
      }
    }
  }
  &__header {
    display: flex;
    align-items: center;
    .title {
      font-size: 23px;
      font-weight: 300;
      margin-top: 2px;
      margin-bottom: 22px;
      flex-grow: 1;
      &::before {
        counter-increment: checkoutSection;
        content: counter(checkoutSection);
        border: 1px solid #3e3e3e;
        border-radius: 50%;
        width: 33px;
        height: 33px;
        display: inline-block;
        color: #3e3e3e;
        font-size: 16px;
        font-weight: 300;
        text-align: center;
        box-sizing: border-box;
        margin-right: 11px;
        padding-left: 1px;
        vertical-align: middle;
        position: relative;
        padding-top: 7px;
      }
    }
    .edit-link {
      display: none;
      float: right;
      font-size: 11px;
      cursor: pointer;
      text-transform: uppercase;
    }
  }
  &__error {
    display: none;
    color: #fff;
    background: #f0523d;
    margin: 10px -22px;
    padding: 5px 22px;
    &.is-show {
      display: block;
    }
  }
  &__item {
    margin-top: 0;
    margin-bottom: 12px;
    .info, .error, .order__email {
      display: block;
      margin-top: 10px;
    }
    &_inline {
      > * {
        margin: 0 0 12px;
        &:last-child {
          margin: 0;
        }
      }
      @media ( --breakpoint-sm-up) {
        display: flex;
        margin-left: -10px;
        margin-right: -10px;
        > * {
          margin: 0 10px;
          flex: 1;
          min-width: 150px;
        }
      }
    }
    &_addon {
      width: 100%;
      position: relative;
      .ui-input {
        display: block;
        padding-right: 80px;
      }
      .button {
        position: absolute;
        top: 0;
        right: 0;
        height: 40px;
        width: auto;
      }
    }
  }
  label + label {
    margin-left: 10px;
  }
}