.menu {
	@extend .list;
	line-height: 1;
	&__item {
		display: inline;
	}
	&__link {
		display: inline-block;
		text-decoration: none;
	}
	&_nav {
		text-align: center;
		.menu__item {
			text-align: center;
			&.active {
				.menu__link {
					color: #C2C2C2;
					&::after {
						content: none
					}
				}
			}
		}
		.menu__link {
			position: relative;
			padding: 5px 15px;
			font-size: 1.5vw;
			font-weight: var(--font-semibold);
			color: var(--color-darkgrey);
			text-transform: uppercase;
			transition: var(--button-speed) all ease-out;
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				width: calc(100% - 30px);
				height: 2px;
				background-color: #C2C2C2;
				z-index: 10;
				transition: all .5s ease-out;
			}
			&:hover {
				text-decoration: none;
				color: #C2C2C2;
				&::after {
					transform: scale(0);
				}
			}

		}
	}
}
