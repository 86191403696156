/* Headings */
:root {
	--heading-font-family: var(--font-family);
	--heading-line-height: 1.2;
	--h1-font-size: 3rem;
	--h2-font-size: 2rem;
	--h3-font-size: 1.75rem;
	--h4-font-size: 1.25rem;
	--h5-font-size: 1rem;
	--h6-font-size: .875rem;
	--heading-font-weight: var(--font-semibold);
	--heading-color: var(--color-black);
}
@custom-selector :--headings h1, h2, h3, h4, h5, h6;

:--headings {
	font-family: var(--heading-font-family);
	font-weight: var(--heading-font-weight);
	line-height: var(--heading-line-height);
	color: var(--heading-color);
	a, a:visited {
		color: inherit;
	}
}
h1, h2 {
	margin: 1.25rem 0 1.5rem;
}
h3, h4, h5, h6 {
	margin: 0.625rem 0 0.625rem;
}

h1 {
	font-size: var(--h1-font-size);
}

h2 {
	font-size: var(--h2-font-size);
}

h3 {
	font-size: var(--h3-font-size);
}

h4 {
	font-size: var(--h4-font-size);
}

h5 {
	font-size: var(--h5-font-size);
}

h6 {
	font-size: var(--h6-font-size);
}
