blockquote {
	font-size: 120%;
	border-left: solid .4rem var(--component-border-color);
	padding: .5rem .5rem .5rem 1rem;
	margin-bottom: 1.5rem;
	margin-left: 0;
}
blockquote :last-child {
	margin-bottom: 0;
}
