.footer {
	border-top: solid 1px #ddd;
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: #fff;
	.copyright {
		font-size: var(--font-size-small);
		margin: 0;
	}
}
