.social {
	@extend .list;
	&__item {
		display: inline-block;
		margin-right: 10px;
		&:last-child {
			margin-right: 0;
		}
	}
	&__link {
		display: inline-block;
		width: 64px;
		height: 64px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: 10px;
		transition: all .3s ease-out;
		&:hover {
			opacity: .65;
		}
	}
}


