:root {
	--link-color: var(--state-info);
	--link-text-decoration: none;
	--link-color-hover: color(var(--link-color) blackness(10%));
	--link-text-decoration-hover: underline;
}
a {
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
	color: var(--link-color);
	text-decoration: var(--link-text-decoration);
	&:hover {
		color: var(--link-color-hover);
		text-decoration: var(--link-text-decoration-hover);
	}
}
.link {
	transition: var(--button-speed) all ease-out;
	&:hover, &:active {
		color: var(--link-color-hover);
		text-decoration: var(--link-text-decoration-hover);
	}
}
.link_mail, .link_phone {
	display: inline-block;
	margin: 5px 0;
	padding-left: 52px;
	background-position: 0 50%;
	background-repeat: no-repeat;
	background-size: 32px 32px;
	color: var(--color-darkgrey);
	text-decoration: none;
	&:hover {
		color: var(--color-darkgrey);
	}
}
.link_phone {
	font-size: 1.5rem;
	font-weight: var(--font-bold);
	background-image: url(../images/svg/cont-phone.svg);
}
.link_mail {
	font-size: 1.125rem;
	font-weight: var(--font-normal);
	background-image: url(../images/svg/cont-email.svg);
}
