.sidebar {
  @media ( --breakpoint-lg-up) {
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    right: 0;
  }
}
.sticky:before,
.sticky:after {
  content: '';
  display: table;
}