.product-card {
  &__image {
    min-height: 360px;
    @media (--breakpoint-md-up) {
      min-height: 450px;
    }
    @media (--breakpoint-lg-up) {
      min-height: 610px;
    }
  }
  &__body {
    .title {
      margin: 20px 0;
      @media (--breakpoint-lg-up) {
        margin: 0 0 20px;
      }
    }
  }
  &__footer {
    padding-top: 20px;
    font-style: italic;
    font-size: .875rem;
  }
}