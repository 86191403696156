
/* Text color */
.text-primary { color: var(--state-primary) !important; }
.text-secondary { color: var(--state-secondary) !important; }
.text-success { color: var(--state-success) !important; }
.text-info { color: var(--state-info) !important; }
.text-warning { color: var(--state-warning) !important; }
.text-danger { color: var(--state-danger) !important; }
.text-brand { color: #b90000 !important; }

/* Text style */
.text-small { font-size: var(--font-size-small) !important; }
.text-bold { font-weight: var(--font-bold) !important; }
.text-italic { font-style: italic !important; }
.text-muted { color: var(--color-muted) !important; }

/* Text alignment */
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }
.text-center { text-align: center !important; }
.text-justify { text-align: justify !important; }
.text-underline { text-decoration: underline !important; }

/* Text case */
.text-lowercase { text-transform: lowercase !important; }
.text-uppercase { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }

/* Text wrapping */
.text-nowrap { white-space: nowrap !important; }

/* Background utilities */
.bg-primary { background-color: var(--state-primary) !important; }
.bg-secondary { background-color: var(--state-secondary) !important; }
.bg-success { background-color: var(--state-success) !important; }
.bg-info { background-color: var(--state-info) !important; }
.bg-warning { background-color: var(--state-warning) !important; }
.bg-danger { background-color: var(--state-danger) !important; }

/* Display utilities */

.hide {	display: none; }
/* visually hidden element */
.visuallyhidden {
	position: absolute;
	clip: rect(0 0 0 0);
	size: 1px;
	margin: -1px;
}
@media (--breakpoint-xs-only) { .hide-xs { display: none !important; } }
@media (--breakpoint-xs-up) { .hide-xs-up { display: none !important; } }
@media (--breakpoint-xs-down) { .hide-xs-down { display: none !important; } }

@media (--breakpoint-sm-only) { .hide-sm { display: none !important; } }
@media (--breakpoint-sm-up) { .hide-sm-up { display: none !important; } }
@media (--breakpoint-sm-down) { .hide-sm-down { display: none !important; } }

@media (--breakpoint-md-only) { .hide-md { display: none !important; } }
@media (--breakpoint-md-up) { .hide-md-up { display: none !important; } }
@media (--breakpoint-md-down) { .hide-md-down { display: none !important; } }

@media (--breakpoint-lg-only) { .hide-lg { display: none !important; } }
@media (--breakpoint-lg-up) { .hide-lg-up { display: none !important; } }
@media (--breakpoint-lg-down) { .hide-lg-down { display: none !important; } }

@media (--breakpoint-xl-only) { .hide-xl { display: none !important; } }
@media (--breakpoint-xl-up) { .hide-xl-up { display: none !important; } }
@media (--breakpoint-xl-down) { .hide-xl-down { display: none !important; } }

/*   Float utilities  */
.clearfix {
	@apply --clearfix;
}

.float-left {
	float: left !important;
}

.float-right {
	float: right !important;
}
.image-left, .image-right {
	max-width: 100%;
}
.image-left {
	float: left;
	margin: 5px 15px 0 0;
}
.image-right {
	float: right;
	margin: 5px 0 0 15px;
}
@media (--breakpoint-sm-down) {
	.image-left, .image-right {
		float: none;
		margin: 5px 0;
	}
}
/*  Spacing utilities */
.no-padding {
	padding: 0;
}
.no-margin {
	margin: 0;
}
/*  full witdh */
.is-full {
	width: 100%;
}
/* Webkit scrollbar */
.ui-scroll {
	overflow-y: auto;
	overflow-x: hidden;
}
.ui-scroll::-webkit-scrollbar {
	width: 5px;
}
.ui-scroll::-webkit-scrollbar-track {
	background: #ccc;
}
.ui-scroll::-webkit-scrollbar-thumb {
	background-color: #a3a3a3;
}
.ui-scroll::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(51,51,51,0.7);
}
/* Message for update old browser */
.buorg {
	border-bottom: 1px solid #000;
	background: #ccc no-repeat 1em 0.55em url(http://browser-update.org/img/dialog-warning.gif);
	text-align: left;
	font-size: var(--font-size-small);
	line-height: 1;
	cursor: pointer;
	color: #000;
a {
	color: var(--color-text);
	font-size: var(--font-size-small);
	&:hover, &:active {
		  color: color(var(--color-text) alpha(-10%));
	  }
	&:visited {
		 color: color(var(--color-text) blackness(80%));
	 }
	}
}


/*  animation */

.fadeIn {
	animation: fadein 2s;
}
.fadeOut {
	animation: fadeout 2s;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeout {
	from {
		opacity: 1
	}
	to {
		opacity: 0;
	}
}
