.cart {
  margin-bottom: 20px;
  &__item {
    position: relative;
    margin-top: 20px;
    min-height: 68px;
    color: #3e3e3e;
    font-size: 12px;
    display: flex;
  }
  &__image {
    max-width: 80px;
    flex: 0 0 80%;
  }
  &__content {
    padding: 0 10px;
    flex: 1 1 auto;
  }
  &__total {
    max-width: 70px;
    flex: 0 0 70px;
    .ui-input {
      padding: 5px 8px;
    }
  }
  .title, .link {
    display: block;
  }
  .title {
    margin-top: 0;
    margin-bottom: 5px;
  }
  .link {
    margin-top: 5px;
  }
}