.tabs {
  position: relative;
  padding-top: 50px;
  [type=radio] {
    display: none;
  }
  &__item {
    &:first-child {
      .tabs__label {
        left: 0;
      }
    }
    &:last-child {
      .tabs__label {
        right: 0;
      }
    }
  }
  &__label {
    display: inline-block;
    position: absolute;
    top: 0;
    width: 50%;
    height: 35px;
    margin-bottom: 12px;
    padding: 7px 10px 10px;
    text-align: center;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    cursor: pointer;
  }
  &__content {
    display: none;
    background-color: #fff;
    overflow: hidden
  }
  [type=radio]:checked ~ label {
    background: #f2f2f2;
  }
  [type=radio]:checked ~ label ~ .tabs__content {
    display: block;
  }
}