:root {
    --button-font-family: var(--font-family);
    --button-font-weight: var(--font-light);
	--button-speed: var(--component-speed);


	--button-color-primary: var(--color-white);
	--button-bg-color-primary: var(--state-primary);


	--button-color-secondary: var(--color-black);
	--button-bg-color-secondary: var(--state-secondary);


	--button-color-success: var(--color-white);
	--button-bg-color-success: var(--state-success);


	--button-color-info: var(--color-white);
	--button-bg-color-info: var(--state-info);


	--button-color-warning: var(--color-white);
	--button-bg-color-warning: var(--state-warning);


	--button-color-danger: var(--color-white);
	--button-bg-color-danger: var(--state-danger);
}


/*  Buttons styling and modifiers */

button,
.button {
	margin: 0;
	background-color: transparent;
	box-shadow: none;
	border: none;
	vertical-align: top;
	touch-action: manipulation;
	white-space: nowrap;
	appearance: none;
	user-select: none;
	text-decoration: none;
	font-family: var(--button-font-family);
	font-weight: var(--button-font-weight);
	cursor: pointer;
	backface-visibility: hidden;
}
.button {
	display: inline-block;
	vertical-align: middle;
	padding: .625rem 1.25rem;
	font-size: var(--font-size);
	color: var(--color-text);
	line-height: 1;
	text-align: center;
	background: transparent;
	border: 0;
	box-shadow: none;
	transition: var(--button-speed) all ease-out;
	outline-offset: -3px;
	&:hover {
		text-decoration: none;
	}
	&:disabled,
	&.disabled {
		text-decoration: none;
		opacity: .5;
		cursor: not-allowed;
	}
	&_md {
		font-size: 1.5rem;
	}
	&_lg {
		font-size: 2rem;
	}
	&_primary {
		color: var(--button-color-primary);
		background-color: var(--button-bg-color-primary);
		outline: solid 3px var(--button-bg-color-primary);
		&:hover, &:active,
		&.active {
			color: var(--button-color-secondary);
			background-color: var(--button-bg-color-secondary);
		}
	}
	&_secondary {
		color: var(--button-color-secondary);
		background-color: var(--button-bg-color-secondary);
		outline: solid 3px var(--button-bg-color-secondary);
		&:hover, &:active,
		&.active {
			color: var(--button-color-primary);
			background-color: var(--button-bg-color-primary);
		}
	}
	&_success {
		color: var(--button-color-success);
		background-color: var(--button-bg-color-success);
		outline: solid 3px var(--button-bg-color-success);
		&:hover, &:active,
		&.active {
			color: var(--button-color-success);
			background-color: color(var(--button-bg-color-success) shade(10%));
		}
	}
	&_info {
		color: var(--button-color-info);
		background-color: var(--button-bg-color-info);
		outline: solid 3px var(--button-bg-color-info);
		&:hover, &:active,
		&.active {
			color: var(--button-color-info);
			background-color: color(var(--button-bg-color-info) shade(10%));
		}
	}
	&_warning {
		color: var(--button-color-warning);
		background-color: var(--button-bg-color-warning);
		outline: solid 3px var(--button-bg-color-warning);
		&:hover, &:active,
		&.active {
			color: var(--button-color-warning);
			background-color: color(var(--button-bg-color-warning) shade(10%));
		}
	}
	&_danger {
		color: var(--button-color-danger);
		background-color: var(--button-bg-color-danger);
		outline: solid 3px var(--button-bg-color-danger);
		&:hover, &:active,
		&.active  {
			color: var(--button-color-danger);
			background-color: color(var(--button-bg-color-danger) shade(10%));
		}
	}
}

.button-top {
	position: fixed;
	right: 40px;
	bottom: 40px;
	width: 60px;
	height: 60px;
	background-color: #fff;
	border-radius: 100%;
	border: solid 1px #ddd;
	display: none;
	cursor: pointer;
	z-index: var(--z-index-popover);
	&::before, &::after {
		content: '';
		display: block;
		position: absolute;
		width: 0;
		height: 0;
		top: 0;
		left: 50%;
	}
	&::before {
		border: solid 16px transparent;
		border-bottom: solid 20px #000;
		margin-left: -16px;
	}
	&::after {
		border: solid 10px transparent;
		border-bottom: solid 12px #fff;
		margin-left: -10px;
		top: 14px;
	}
}