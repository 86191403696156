.video-responsive, .image-responsive {
  position: relative;
  margin-bottom: 20px;
  height: 0;
  padding-bottom: 68.56%;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0;
  }
}