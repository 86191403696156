.badge {
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 50px;
  height: 50px;
  background-image: url(../images/discount.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  text-align: center;
  z-index: 100;
  &__value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    line-height: 1;
    font-weight: 600;
    font-size: .95rem;
    color: #fff;
    white-space: nowrap;
  }
}