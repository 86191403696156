.card {
  display: block;
  background: rgba(255, 255, 255, .6);
  box-shadow: 0 15px 18px rgba(0, 0, 0, .1);
  transition: all .25s ease-out;
  &:hover {
    box-shadow: 0 5px 1px rgba(0, 0, 0, .1);
  }
  &__body {
    padding: 10px;
    text-align: center;
  }
  &__image {
    display: block;
    position: relative;
    padding-bottom: 75%;
    overflow: hidden;
    .image {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: transform .8s ease-in-out;
      object-fit: cover;
    }
    &:hover {
      .image {
        transform: scale(2.15);
        transition: transform 1.8s ease-in-out;
      }
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .title {
    margin: 12px 0 0;
    font-weight: 600;
  }
  .price {
    display: block;
    margin: 8px 0 0;
    color: var(--color-grey);
    font-weight: 400;
    span {
      color: var(--color-darkgrey);
    }
  }
  &:hover {
    text-decoration: none;
  }
}